const baseToReplace = 'https://new-bill-me.s3.ap-south-1.amazonaws.com';
const baseToReplaceWith = 'https://assets.billme.co.in';

const assetUrlReplacer = (url: string) => {
  let modifiedUrl = url;
  if (modifiedUrl && modifiedUrl.includes(baseToReplace)) {
    modifiedUrl = modifiedUrl.replace(baseToReplace, baseToReplaceWith);
  }
  return modifiedUrl;
};

export default assetUrlReplacer;
