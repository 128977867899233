import React from 'react';

import {
  MarcaFacebookIcon,
  MarcaInstagramIcon,
  YoutubeIcon,
  LinkedInIcon,
  WhatsAppIcon,
  NewTwitterIcon,
  PinterestIcon,
  SnapchatIcon,
  TollFreeIcon,
} from '../../assets/images/social';

import { TwitterIcon, WebsiteIcon } from '../../assets/images/decathlon';

import { $Flexbox } from '../../css/styles';

const socialConfig = {
  // "connplex": {
  // 	order: ["website", "instagram", "facebook", "youtube"],
  // 	facebook: "https://www.facebook.com/connplex",
  // 	website: "https://www.theconnplex.com/",
  // 	instagram: "https://www.instagram.com/theconnplex/",
  // 	youtube: "https://www.youtube.com/c/TheConnplexSmartTheatres"
  // },
  prasads: {
    order: ['facebook', 'instagram', 'youtube', 'twitter', 'linkedin'],
    facebook: 'https://www.facebook.com/PrasadsMultiplex',
    youtube: 'https://www.youtube.com/channel/UCWWU_CYGVUUxLPmsO-5-E3Q',
    instagram: 'https://www.instagram.com/prasadsmultiplx/',
    twitter: 'https://twitter.com/PrasadsCinemas',
    linkedin: 'https://www.linkedin.com/in/prasads-multiplex-96269125b/',
  },
  carnival: {
    order: ['facebook', 'instagram', 'youtube'],
    facebook: 'https://www.facebook.com/CarnivalCinemaIndia/',
    youtube: 'https://www.youtube.com/channel/UCitDK0367rpy8_QWLDqq5rQ',
    instagram: 'https://www.instagram.com/carnivalcine/',
  },
  miraj: {
    order: ['facebook', 'instagram'],
    facebook: 'https://www.facebook.com/MirajCinemas/',
    instagram: 'https://www.instagram.com/mirajcinemas/',
  },
  momaiya: {
    order: ['facebook', 'website', 'instagram'],
    facebook: 'https://www.facebook.com/MLP315',
    website: 'http://www.momaiyalowprice.com',
    instagram: 'https://www.instagram.com/momaiyalow/',
  },
  sprvlCrocodile: {
    order: ['instagram', 'facebook', 'youtube', 'website'],
    facebook: 'https://www.facebook.com/crocodile.in',
    website: 'https://crocodile.in/',
    instagram: 'https://www.instagram.com/crocodile_india/',
    youtube: 'https://www.youtube.com/@crocodileindia',
  },
  sprvlAngelAndRocket: {
    order: ['instagram', 'facebook', 'youtube', 'website'],
    facebook: 'https://www.facebook.com/Angelandrocket.in/',
    website: 'https://angelandrocket.in/',
    instagram: 'https://www.instagram.com/angelandrocket.in/',
    youtube: 'https://www.youtube.com/@angelandrocketindia',
  },
  bodyBuildingIndia: {
    order: ['instagram', 'facebook', 'linkedin', 'youtube'],
    facebook: 'https://www.facebook.com/BodyBuildingIndiaCom?mibextid=LQQJ4d',
    linkedin: 'https://www.linkedin.com/company/bbibodybuildingindia/',
    instagram: 'https://www.instagram.com/bodybuildingindiaofficial/',
    youtube: 'https://www.youtube.com/@bodybuildingindiaofficial',
  },
  mahavirHomeStore: {
    order: ['facebook', 'instagram', 'whatsapp', 'website'],
    facebook: 'https://www.facebook.com/mahavirthehomestore',
    website: 'https://www.mahavirhomestore.com/',
    instagram: 'https://www.instagram.com/mahavirthehomestore',
    whatsapp:
      'https://api.whatsapp.com/send?phone=+919342700300&text=Hi!%20I%20want%20to%20shop%20on%20the%20Mahavir%20Home%20Store%20Website,%20and%20have%20a%20question',
  },
  burgetKingIndia: {
    order: ['facebook', 'instagram', 'twitter', 'youtube'],
    facebook: 'https://www.facebook.com/burgerkingindia/',
    instagram: 'https://www.instagram.com/burgerkingindia/',
    twitter: 'https://twitter.com/burgerkingindia',
    youtube: 'https://www.youtube.com/user/BurgerKingIndia',
  },
  westside: {
    order: ['website', 'twitter', 'facebook', 'instagram', 'youtube'],
    facebook: 'https://www.facebook.com/profile.php/?id=100064845214824',
    website: 'https://www.westside.com/',
    instagram: 'https://www.instagram.com/westsidestores?igsh=MXYzcG1lYW9jOGNjMA==',
    twitter: 'https://twitter.com/westsidestores',
    youtube: 'https://youtube.com/@WestsideStores?si=XCDsfuKdceaAuNwt',
  },
  mahavirHomeStore: {
    order: ['facebook', 'instagram', 'whatsapp', 'website'],
    facebook: 'https://www.facebook.com/mahavirthehomestore',
    website: 'https://www.mahavirhomestore.com/',
    instagram: 'https://www.instagram.com/mahavirthehomestore',
    whatsapp:
      'https://api.whatsapp.com/send?phone=+919342700300&text=Hi!%20I%20want%20to%20shop%20on%20the%20Mahavir%20Home%20Store%20Website,%20and%20have%20a%20question',
  },
  demoCinepolis: {
    order: ['instagram', 'xnewtwitter', 'facebook', 'website'],
    facebook: 'https://www.facebook.com/CinepolisIndia',
    website: 'https://www.cinepolisindia.com/',
    instagram: 'https://www.instagram.com/cinepolisindia/',
    xnewtwitter: 'https://twitter.com/i/flow/login?redirect_after_login=%2FIndiaCinepolis',
  },
  sangamSelection: {
    order: ['facebook', 'instagram'],
    facebook: 'https://www.facebook.com/SangamSelectionSurat?mibextid=qWsEUC',
    instagram: 'https://www.instagram.com/sangamselectionsurat?utm_source=qr',
  },
  houseofFett: {
    order: ['website', 'facebook', 'instagram', 'youtube'],
    website: 'https://houseoffett.com/',
    facebook: 'https://www.facebook.com/houseoffettofficial',
    instagram: 'https://www.instagram.com/houseoffett/',
    youtube: 'https://www.youtube.com/@houseoffett3377',
  },
  cinepolisIndonesiaFnb: {
    order: ['facebook', 'instagram', 'website', 'xnewtwitter', 'youtube', 'whatsapp'],
    facebook: 'https://www.facebook.com/cinepolisID/',
    instagram: 'https://www.instagram.com/cinepolisid/',
    website: 'https://cinepolis.co.id/',
    xnewtwitter: 'https://twitter.com/cinepolisid',
    youtube: 'https://www.youtube.com/channel/UCP70SpqoP28WPYIHkzf_Y8Q',
    whatsapp:
      'https://api.whatsapp.com/send/?phone=6287777731078&text=Hi+Cinepolis+Team%2C+saya+ingin+bertanya&type=phone_number&app_absent=0',
  },
  mulmul: {
    order: ['facebook', 'instagram', 'xnewtwitter', 'youtube', 'website'],
    facebook: 'https://www.facebook.com/shopmulmul/',
    instagram: 'https://www.instagram.com/shopmulmul/',
    xnewtwitter: 'https://twitter.com/shopmulmul',
    youtube: 'https://www.youtube.com/channel/UCyZDJM_8O_xWDeDj3pYgz_w',
    website: 'https://shopmulmul.com/',
  },
  bewakoof: {
    order: ['facebook', 'instagram', 'xnewtwitter', 'youtube', 'pinterest', 'snapchat'],
    facebook: 'https://www.facebook.com/bewakoofcom',
    instagram: 'https://www.instagram.com/bewakoofofficial/',
    xnewtwitter: 'https://x.com/bewakoof',
    youtube: 'https://www.youtube.com/@bewakoof9884',
    pinterest: 'https://in.pinterest.com/bewakoof/',
    snapchat: 'https://www.snapchat.com/add/bewakoof_india?share_id=EJe6-fzh_Tc&locale=en-IN',
  },
  sugarcosmetics: {
    order: ['facebook', 'youtube', 'xnewtwitter', 'instagram', 'linkedin', 'whatsapp'],
    facebook: 'https://www.facebook.com/trySUGAR/',
    instagram: 'https://www.instagram.com/trysugar/?hl=en',
    youtube: 'https://www.youtube.com/channel/UCKVqnev2idvmUNKc2b91B8g',
    xnewtwitter: 'https://x.com/trysugar',
    linkedin: 'https://www.linkedin.com/company/sugar-cosmetics/',
    whatsapp: 'https://whatsapp.com/channel/0029VaHLCNGKGGGOgD54ot1Z',
  },
  superk: {
    order: ['instagram', 'facebook', 'whatsapp', 'youtube'],
    facebook: 'https://www.facebook.com/superKsupermarket/',
    instagram: 'https://www.instagram.com/superk_supermarket',
    whatsapp: 'https://www.whatsapp.com/channel/0029VaChV9tFHWpxz4h9ju3m',
    youtube: 'https://www.youtube.com/@superk9260',
  },
  zimson: {
    order: ['website', 'facebook', 'instagram'],
    website: 'https://zimsonwatches.com/',
    facebook: 'https://www.facebook.com/zimsonwatches/',
    instagram: 'https://www.instagram.com/zimsonwatches_official/?igsh=MXZ2OHF5bGd4czlrbQ%3D%3D#',
  },
  miArcus: {
    order: ['facebook', 'instagram', 'xnewtwitter', 'whatsapp', 'youtube'],
    xnewtwitter: 'https://x.com/i/flow/login?redirect_after_login=%2Fofficialmiarcus',
    facebook: 'https://www.facebook.com/MiArcusOfficial',
    instagram: 'https://www.instagram.com/official_miarcus/#',
    youtube: 'https://www.youtube.com/@official_miarcus',
    whatsapp: 'https://api.whatsapp.com/send?phone=918872099913&text=Hi',
  },
  goColors: {
    order: ['facebook', 'instagram', 'youtube', 'linkedin'],
    facebook: 'https://www.facebook.com/GoColorsOfficial/',
    instagram: 'https://www.instagram.com/gocolors/?hl=en',
    linkedin: 'https://www.linkedin.com/company/6985216/admin/dashboard/',
    youtube: 'https://www.youtube.com/channel/UCBoCRIMYgPErDLj5PAduCNw',
  },
  sammm: {
    order: ['instagram'],
    instagram: 'https://www.instagram.com/sammmmsquad/',
  },
  manyavar: {
    order: ['website', 'facebook', 'instagram', 'xnewtwitter', 'tollfree', 'pinterest', 'youtube'],
    website: 'https://www.manyavar.com/',
    facebook: 'https://www.facebook.com/Manyavar/',
    instagram: 'https://www.instagram.com/manyavar/',
    tollfree: 'tel:+1800-120-000-500',
    xnewtwitter: 'https://x.com/Manyavar_',
    youtube: 'https://www.youtube.com/@ManyavarCelebrationWear',
    pinterest: 'https://www.pinterest.com/manyavar_/',
  },
  twamev: {
    order: ['website', 'facebook', 'instagram', 'xnewtwitter', 'tollfree', 'youtube'],
    website: 'https://www.twamev.com/',
    facebook: 'https://www.facebook.com/twamev.official',
    instagram: 'https://www.instagram.com/twamev.official/',
    youtube: 'https://www.youtube.com/@TwamevOfficial',
    tollfree: 'tel:+1800-120-000-500',
    xnewtwitter: 'https://x.com/TwamevOfficial',
  },
  mohey: {
    order: ['website', 'facebook', 'instagram', 'xnewtwitter', 'tollfree', 'youtube'],
    website: 'https://www.manyavar.com/',
    facebook: 'https://www.facebook.com/search/top?q=mohey',
    instagram: 'https://www.instagram.com/moheyfashion/',
    xnewtwitter: 'https://x.com/moheyfashion',
    tollfree: 'tel:+1800-120-000-500',
    youtube: 'https://www.youtube.com/@mohey3859',
  },
  mebaz: {
    order: ['website', 'facebook', 'instagram', 'xnewtwitter', 'tollfree', 'youtube'],
    website: 'https://www.mebaz.com/',
    facebook: 'https://www.facebook.com/MebazWorld',
    instagram: 'https://www.instagram.com/mebazworld/',
    xnewtwitter: 'https://x.com/MebazWorld',
    tollfree: 'tel:+1800-120-000-500',
    youtube: 'https://www.youtube.com/@mebazindia',
  },
  tfs: {
    order: ['instagram', 'facebook', 'linkedin', 'xnewtwitter'],
    instagram:
      'https://www.instagram.com/travelfoodservices?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==',
    facebook: 'https://www.facebook.com/profile.php?id=100084309904408',
    linkedin: 'https://www.linkedin.com/company/travel-food-services/',
    xnewtwitter: 'https://x.com/Info_TFS',
  },
  wowChicken: {
    order: ['instagram'],
    instagram: 'https://www.instagram.com/wow',
  },
  wowKulfi: {
    order: ['instagram'],
    instagram: 'https://www.instagram.com/wow',
  },
  pureHomeandLiving: {
    order: ['instagram', 'facebook', 'xnewtwitter', 'pinterest', 'youtube'],
    instagram:
      'https://www.instagram.com/accounts/login/?next=https%3A%2F%2Fwww.instagram.com%2Fpurehomeandliving%2F&is_from_rle',
    facebook: 'https://www.facebook.com/purehomeplusliving/',
    pinterest: 'https://in.pinterest.com/purehomeandliving/_created/',
    xnewtwitter: 'https://x.com/purehomeliving?lang=en',
    youtube: 'https://www.youtube.com/@purehomeandliving',
  },
};

const WebsiteSocial = (link) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <WebsiteIcon style={{ width: '33px', height: '30px' }} />
    </a>
  );
};

const FacebookSocial = (link) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <MarcaFacebookIcon style={{ width: '30px', height: '30px' }} />
    </a>
  );
};

const InstagramSocial = (link) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <MarcaInstagramIcon style={{ width: '30px', height: '30px' }} />
    </a>
  );
};

const YoutubeSocial = (link) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <YoutubeIcon style={{ width: '33px', height: '30px' }} />
    </a>
  );
};

const TwitterSocial = (link) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <TwitterIcon style={{ width: '33px', height: '30px' }} />
    </a>
  );
};

const LinkedInSocial = (link) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <LinkedInIcon style={{ width: '33px', height: '30px' }} />
    </a>
  );
};
const WhatsappInSocial = (link) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <WhatsAppIcon style={{ width: '33px', height: '30px' }} />
    </a>
  );
};
const XSocial = (link) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <NewTwitterIcon style={{ width: '33px', height: '30px' }} />
    </a>
  );
};
const PinterestSocial = (link) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <PinterestIcon style={{ width: '33px', height: '30px' }} />
    </a>
  );
};
const SnapchatSocial = (link) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <SnapchatIcon style={{ width: '33px', height: '30px' }} />
    </a>
  );
};
const TollFreeSocial = (link) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <TollFreeIcon style={{ width: '33px', height: '33px' }} />
    </a>
  );
};

const GetSocialComponent = (link, socialType) => {
  switch (socialType) {
    case 'facebook':
      return FacebookSocial(link);
    case 'instagram':
      return InstagramSocial(link);
    case 'website':
      return WebsiteSocial(link);
    case 'youtube':
      return YoutubeSocial(link);
    case 'linkedin':
      return LinkedInSocial(link);
    case 'twitter':
      return TwitterSocial(link);
    case 'whatsapp':
      return WhatsappInSocial(link);
    case 'xnewtwitter':
      return XSocial(link);
    case 'pinterest':
      return PinterestSocial(link);
    case 'snapchat':
      return SnapchatSocial(link);
    case 'tollfree':
      return TollFreeSocial(link);
  }
};

export const SocialGlobal = ({ template }) => (
  <$Flexbox
    justify={socialConfig[template].order.length > 2 ? 'space-around' : 'center'}
    padding="16px 0px"
    gap={socialConfig[template].order.length > 2 ? false : '50px'}>
    {socialConfig[template].order.map((social) => {
      return GetSocialComponent(socialConfig[template][social], social);
    })}
  </$Flexbox>
);
